import React from 'react'
import { Form } from 'pages'
import schema from '_schema/volunteeringSubmission'
import routes from './routes'
import { useSelector } from 'react-redux'

export const New = ({ schema: definitions }) => {
  const apiConfig = useSelector(state => state.apiConfig)
  const isTatrzanski = apiConfig.hostname.name === 'TATRZANSKI'

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={isTatrzanski ? schema.resourceNew : schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().index.path}
      editPath={routes().edit.path}
      storeCollectionId={schema.endpoint}
      width={600}
    />
  )
}
