import adminSchema from '_schema/admin'
import adminGroupSchema from '_schema/adminGroup'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'
import news from '_schema/news'
import newsCategory from '_schema/newsCategory'
import contest from '_schema/contest'
import customer from '_schema/customer'
import homeBanner from '_schema/homeBanner'
import packageFaq from './packageFaq'
import packageFile from './packageFile'
import packageGallery from './packageGallery'
import packageLogotype from './packageLogotype'
import footer from './footer'
import event from './event'
import trail from './trail'
import park from './park'
import volunteering from './volunteering'
import shop from './shopProduct'
import bip from './bipPage'
import tourismNotice from './tourismNotice'
import config from './config'
import socialMediaItem from './socialMediaItem'
import mainMenu from './mainMenu'
import formSet from './formset'
import crewItem from './crewItem'
import accomm from './accommodation'
import accommCat from './accommodationCategory'
import accommUser from './accommodationUser'
import accommConf from './accommodationConfig'
import accommMsg from './accommodationMessage'
import accommPdf from './accommodationPdf'
import accommNot from './accommodationNotice'
import tourney from './tourney'
import tourneyUser from './tourneyUser'
import tourneyConfig from './tourneyConfig'
import weatherLoc from './weatherLocation'
import matter from './matter'
import guide from './guide'
import churchiscoFiacre from './churchiscoFiacre'
import seaEyeFiacre from './seaEyeFiacre'
import lesson from './lesson'
import searchedWord from './searchedWord'
import seoRedirect from './seoRedirect'
import user from './user'
import reservationEvent from './reservationEvent'

const resources = () => ({
  [page.access]: 'Pages',
  [adminSchema.access]: 'Administrators',
  [adminGroupSchema.access]: 'Administrator Groups',
  [loginEntry.access]: 'Login entries',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  [news.access]: 'T_MODULE_NEWS',
  [newsCategory.access]: 'T_MODULE_NEWS_CATEGORY_ACCESS',
  [contest.access]: 'Konkursy',
  [matter.access]: 'Załatw sprawę',
  [accomm.access]: 'Noclegi',
  [accommCat.access]: 'Noclegi - kategorie',
  [accommUser.access]: 'Noclegi - użytkownicy',
  [accommMsg.access]: 'Noclegi - wiadomosci',
  [accommNot.access]: 'Noclegi - powiadomienia',
  [accommPdf.access]: 'Noclegi - pdfy',
  [accommConf.access]: 'Noclegi - konfiguracja',
  [tourney.access]: 'Turnieje',
  [tourneyUser.access]: 'Turnieje - użytkownicy',
  [tourneyConfig.access]: 'Turnieje - konfiguracja',
  [customer.access]: 'T_MODULE_CUSTOMER',
  [homeBanner.access]: 'T_MODULE_HOME_BANNER',
  [packageFaq.access]: 'T_RESOURCE_PACKAGE_FAQ',
  [packageFile.access]: 'T_RESOURCE_PACKAGE_FILE',
  [packageGallery.access]: 'T_RESOURCE_PACKAGE_GALLERY',
  [packageLogotype.access]: 'T_RESOURCE_PACKAGE_LOGOTYPE',
  [footer.access]: 'T_RESOURCE_FOOTER',
  [event.access]: 'T_RESOURCE_EVENT',
  [trail.access]: 'T_RESOURCE_TRAIL',
  [park.access]: 'T_RESOURCE_PARK',
  [volunteering.access]: 'T_RESOURCE_VOLUNTEERING',
  [shop.access]: 'T_RESOURCE_SHOP',
  [bip.access]: 'T_RESOURCE_BIP',
  [tourismNotice.access]: 'T_RESOURCE_TOURISM_NOTICE',
  [config.access]: 'T_RESOURCE_CONFIG',
  [seoRedirect.access]: 'T_RESOURCE_SEO_REDIRECT',
  [socialMediaItem.access]: 'T_RESOURCE_SOCIAL_MEDIA',
  [mainMenu.access]: 'T_RESOURCE_MAIN_MENU',
  [weatherLoc.access]: 'Punkty pogodowe',
  [searchedWord.access]: 'Szukane wyrażenia',
  [formSet.access]: 'T_RESOURCE_FORMSET',
  [crewItem.access]: 'T_RESOURCE_CREW',
  [guide.access]: 'T_RESOURCE_GUIDE',
  [churchiscoFiacre.access]: 'T_RESOURCE_CHURCHISCO_FIACRE',
  [seaEyeFiacre.access]: 'T_RESOURCE_SEA_EYE_FIACRE',
  [lesson.access]: 'T_RESOURCE_LESSON',
  [user.access]: 'Usunięci użytkownicy',
  [reservationEvent.access]: 'Rezerwacje',
})

export default resources
