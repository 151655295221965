import { LANGS_IRI } from '../_lib/langs'
import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/volunteering_config',
  resource: {
    definition: '#VolunteeringConfig',
    properties: {
      clausePage: {
        type: 'resource',
        endpoint: `/api/pages?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Strona klauzuli informacyjnej',
        hint:
          'Gdy akcja wolontariacka nie ma uzupełnionego linku do klauzuli informacyjnej, to zostanie użyta ta strona.',
      },
      email: {
        type: 'string',
        label: 'Adres email',
        hint: 'Wyświetlany w wiadomości email',
      },
      phone: {
        type: 'string',
        label: 'Numer telefonu',
        hint: 'Wyświetlany w wiadomości email',
      },
      normalTemplate: {
        label: 'Wyświetlaj szablon artykułu',
        hint:
          'Wyświetlaj artykuł zamiast listy akcji wolontariackich na podstronie "wolontariat"',
      },
    },
  },
  resourceNew: {
    definition: '#VolunteeringConfig',
    properties: {
      clausePage: {
        type: 'resource',
        endpoint: `/api/pages?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Strona klauzuli informacyjnej',
        hint:
          'Gdy akcja wolontariacka nie ma uzupełnionego linku do klauzuli informacyjnej, to zostanie użyta ta strona.',
      },
      email: {
        type: 'string',
        label: 'Adres email',
        hint: 'Wyświetlany w wiadomości email',
      },
      phone: {
        type: 'string',
        label: 'Numer telefonu',
        hint: 'Wyświetlany w wiadomości email',
      },
      normalTemplate: {
        label: 'Wyświetlaj szablon artykułu',
        hint:
          'Wyświetlaj artykuł zamiast listy akcji wolontariackich na podstronie "wolontariat"',
      },
      importDialogConsent: {
        type: 'tinymce',
        label:
          'Treść zgody w oknie dialogowym przy pierwszym logowaniu zaimportowanego wolontariusza',
      },
    },
  },
  subresources: {
    consents: {
      endpoint: '/api/volunteering_config_consents',
      definition: '#VolunteeringConfigConsent',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              type: FIELD_TYPES.TINYMCE,
              description: 'Treść',
              hint: '',
            },
          },
        },
      },
    },
  },
}

export default schema
