import fileSchema from './media'

const schema = {
  endpoint: '/api/volunteering_submissions',
  resource: {
    definition: '#VolunteeringSubmission',
    properties: {
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
      },
      email: {
        description: 'T_GENERAL_EMAIL',
      },
      phone: {
        description: 'Numer telefonu',
      },
      education: {
        type: 'resource',
        endpoint: '/api/education_levels',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Poziom wykształcenia',
      },
      fieldOfStudy: {
        description: 'Kierunek studiów',
      },
      nameOfTheUniversity: {
        description: 'Nazwa uczelni',
      },
      universityAddress: {
        description: 'Adres uczelni',
      },
      yearOfGraduation: {
        description: 'Rok ukończenia',
      },
      stillStudying: {
        description: 'Czy nadal studiuje?',
      },
      yearStarted: {
        description: 'Rok rozpoczęcia',
      },
      notWorking: {
        description: 'Nie pracuje',
      },
      workPosition: {
        description: 'Stanowisko',
      },
      workContract: {
        type: 'resource',
        endpoint: '/api/employment_contract_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Typ umowy',
      },
      fte: {
        type: 'resource',
        endpoint: '/api/employment_ftes',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Etat',
      },
      workName: {
        description: 'Nazwa firmy',
      },
      workAddress: {
        description: 'Adres firmy',
      },
      answer1: {
        type: 'textarea',
        description: 'Odpowiedź 1',
      },
      answer2: {
        type: 'textarea',
        description: 'Odpowiedź 2',
      },
      answer3: {
        type: 'textarea',
        description: 'Odpowiedź 3',
      },
      consentHealth: {
        description: 'Oświadczenie o zdrowiu',
      },
      consentInformationDisclaimer: {
        description: 'Zgoda na klauzulę informacyjną',
      },
      consentDataPrivacy: {
        description: 'Zgoda na przetwarzanie danych',
      },
      consentNewsletter: {
        description: 'Zgoda na newsletter',
      },
      skipUserNotification: {
        description:
          'Nie wysyłaj powiadomienia do wolontariusza o zmianie statusu',
        hint: 'Niepoprawny email może uniemożliwić zmianę statusu',
      },
      status: {
        type: 'resource',
        endpoint: '/api/status_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Status',
        hint:
          'Zmiana statusu spowoduje wysłanie wiadomości e-mail do użytkownika',
      },
      media: {
        type: 'file',
        description: 'Załącznik',
        endpoint: fileSchema.protectedEndpoint,
        validate: ['maxSize'],
      },
    },
  },
  resourceNew: {
    definition: '#VolunteeringSubmission',
    properties: {
      firstName: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      lastName: {
        description: 'T_GENERAL_SURNAME',
      },
      email: {
        description: 'T_GENERAL_EMAIL',
      },
      phone: {
        description: 'Numer telefonu',
      },
      birthDate: {
        type: 'date',
        description: 'Data urodzenia',
      },
      childAbove15: {
        description: 'Zgłaszam moje dziecko (powyżej 15r.)',
      },
      answer1: {
        type: 'textarea',
        description: 'Odpowiedź 1',
      },
      answer2: {
        type: 'textarea',
        description: 'Odpowiedź 2',
      },
      answer3: {
        type: 'textarea',
        description: 'Odpowiedź 3',
      },
      consentHealth: {
        description: 'Oświadczenie o zdrowiu',
      },
      consentAdult: {
        description: 'Oświadczam, że mam 18 lat',
      },
      consentInformationDisclaimer: {
        description: 'Zgoda na klauzulę informacyjną',
      },
      consentDataPrivacy: {
        description: 'Zgoda na przetwarzanie danych',
      },
      consentNewsletter: {
        description: 'Zgoda na newsletter',
      },
      skipUserNotification: {
        description:
          'Nie wysyłaj powiadomienia do wolontariusza o zmianie statusu',
        hint: 'Niepoprawny email może uniemożliwić zmianę statusu',
      },
      status: {
        type: 'resource',
        endpoint: '/api/status_types',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'Status',
        hint:
          'Zmiana statusu spowoduje wysłanie wiadomości e-mail do użytkownika',
      },
      media: {
        type: 'file',
        description: 'Załącznik',
        endpoint: fileSchema.protectedEndpoint,
        validate: ['maxSize'],
      },
    },
  },
}

export default schema
