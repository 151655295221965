import React from 'react'
import { Form } from 'pages'
import schema from '_schema/volunteeringConfig'
import { EmbeddedCollection } from '../../components/embedded'
import { translate } from '../../_helpers/translate'
import { LANGS_IRI } from '../../_lib/langs'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`

  const { match } = rest

  const apiConfig = useSelector(state => state.apiConfig)
  const isTatrzanski = apiConfig.hostname.name === 'TATRZANSKI'

  const schemaResource = isTatrzanski ? schema.resourceNew : schema.resource

  return (
    <div style={{ marginBottom: '30px' }}>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schemaResource}
        iri={iri}
        method="PUT"
        url={iri}
        storeCollectionId={schema.endpoint}
        sidebar={false}
        width="100%"
      >
        {isTatrzanski && (
          <EmbeddedCollection
            endpoint={`${schema.subresources.consents.endpoint}?pagination=false`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.consents.properties}
            definitionSchema={
              definitions[schema.subresources.consents.definition]
            }
            headerTitle={translate('Zgody')}
            panelTitle={translate('Zgoda')}
            titleAccessor={`translations.${LANGS_IRI.PL}.title`}
            statable={false}
            key={iri}
            paginable={false}
            orderable={false}
            hideAddButton={true}
            deleteDisabled={true}
          />
        )}
      </Form>
    </div>
  )
}
