import React from 'react'
import { Form } from 'pages'
import schema from '_schema/volunteeringSubmission'
import routes from './routes'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const apiConfig = useSelector(state => state.apiConfig)
  const isTatrzanski = apiConfig.hostname.name === 'TATRZANSKI'

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={isTatrzanski ? schema.resourceNew : schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      modifyProperties={(properties, resource) => {
        if (!resource?.subscription) {
          delete properties.subscriptionFrom
          delete properties.sender
        }

        return properties
      }}
      sidebar={false}
      width={600}
    ></Form>
  )
}
